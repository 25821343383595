import * as React from "react";
import "../assets/style.css";

const Error = ({message}) => {
    return (
        <div className="error">
            {message}
        </div>
    );
};

export default Error;
